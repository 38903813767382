import React, { useState, useEffect } from "react";
import axios from "axios";

export default function CheckData({ idAgent }) {
  const [data, setData] = useState({
    agentName: "",
    agentEmail: "",
    agentDepartment: "",
    agentPosition: "",
    agentSupervisor: "",
    agentCountry: "",
  });
  const [cpu, setCpu] = useState("");
  const [ram, setRam] = useState(0);
  const [hdd, setHdd] = useState(0);
  const [so, setSo] = useState("");
  const [cpuP, setCpuP] = useState(0);
  const [ramP, setRamP] = useState(0);
  const [hddP, setHddP] = useState(0);
  const [download, setDownload] = useState(0);
  const [upload, setUpload] = useState(0);
  const [latency, setLatency] = useState(0);
  const [jitter, setJitter] = useState(0);
  const [lost, setLost] = useState(0);
  const [delayed, setDelayed] = useState(0);
  const [bufferbloat, setBufferbloat] = useState("");
  const [webrtc, setWebrtc] = useState("");
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (idAgent) {
      axios.get(`http://localhost:8000/api/employee/${idAgent}`)
        .then(response => {
          const agentData = response.data;
          setData({
            agentName: agentData.firstName + " " + agentData.lastName,
            agentEmail: agentData.email,
            agentDepartment: agentData.department,
            agentPosition: agentData.jobTitle,
            agentSupervisor: agentData.idSup,
            agentCountry: agentData.country,
          });
          setCpu(agentData.cpu);
          setRam(agentData.ram);
          setHdd(agentData.hdd);
          setSo(agentData.so);
          setCpuP(agentData.cpuP);
          setRamP(agentData.ramP);
          setHddP(agentData.hddP);
          setDownload(agentData.download);
          setUpload(agentData.upload);
          setLatency(agentData.latency);
          setJitter(agentData.jitter);
          setLost(agentData.lost);
          setDelayed(agentData.delayed);
          setBufferbloat(agentData.bufferbloat);
          setWebrtc(agentData.webrtc);
          setDocuments(agentData.documents || []);
        })
        .catch(error => {
          console.error('There was an error fetching the agent data!', error);
        });
    }
  }, [idAgent]);

  const handleAddDocument = () => {
    setDocuments([...documents, `Documento ${documents.length + 1}`]);
  };

  const handleRemoveDocument = (index) => {
    setDocuments(documents.filter((_, i) => i !== index));
  };

  const handleFileChange = (index, file) => {
    const newDocuments = [...documents];
    newDocuments[index] = file;
    setDocuments(newDocuments);
  };

  return (
    <div className="flex flex-col w-full h-full ps-2">
      <div id="infoTab" className="dataTab p-1">
        <h4 className="text-lg md:text-2xl text-main-dark font-bold text-center">
          Información General del Agente
        </h4>
        <div className="flex flex-col mt-3">
          <label htmlFor="agentName">Nombre:</label>
          <input id="agentName" disabled type="text" value={data.agentName} />
        </div>
        <div className="flex flex-col">
          <label htmlFor="agentEmail">Correo:</label>
          <input
            id="agentEmail"
            disabled
            type="email"
            value={data.agentEmail}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="agentDepartment">Cliente:</label>
          <input
            id="agentDepartment"
            disabled
            type="text"
            value={data.agentDepartment}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="agentPosition">Posición:</label>
          <input
            id="agentPosition"
            disabled
            type="text"
            value={data.agentPosition}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="agentSupervisor">Supervisor:</label>
          <input
            id="agentSupervisor"
            disabled
            type="text"
            value={data.agentSupervisor}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="agentCountry">País:</label>
          <input
            id="agentCountry"
            disabled
            type="text"
            value={data.agentCountry}
          />
        </div>
      </div>
      <div id="specsTab" className="hidden dataTab p-1">
        <h4 className="text-lg md:text-2xl text-main-dark font-bold text-center">
          Información de la Computadora
        </h4>
        <div className="flex flex-col mt-3">
          <label htmlFor="cpu">Procesador:</label>
          <input
            autoFocus={true}
            id="cpu"
            type="text"
            value={cpu}
            onChange={(e) => setCpu(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="ram">RAM (GB):</label>
          <input
            max={64}
            min={4}
            id="ram"
            type="number"
            value={ram}
            onChange={(e) => setRam(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="hdd">Almacenamiento (GB):</label>
          <input
            max={8192}
            min={128}
            id="hdd"
            type="number"
            value={hdd}
            placeholder="256"
            onChange={(e) => setHdd(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="os">Sistema Operativo:</label>
          <input
            id="os"
            type="text"
            value={so}
            onChange={(e) => setSo(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="cpuP">CPU %:</label>
          <input
            id="cpuP"
            type="number"
            value={cpuP}
            onChange={(e) => setCpuP(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="ramP">RAM %:</label>
          <input
            id="ramP"
            type="number"
            value={ramP}
            onChange={(e) => setRamP(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="hddP">HDD %:</label>
          <input
            id="hddP"
            type="number"
            value={hddP}
            onChange={(e) => setHddP(e.target.value)}
          />
        </div>
      </div>
      <div id="testTab" className="hidden dataTab p-1">
        <h4 className="text-lg md:text-2xl text-main-dark font-bold text-center">
          Información de la Red
        </h4>
        <div className="flex flex-col mt-3">
          <label htmlFor="download">Velocidad de Descarga (MB):</label>
          <input
            id="download"
            type="number"
            value={download}
            onChange={(e) => setDownload(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="upload">Velocidad de Subida (MB):</label>
          <input
            id="upload"
            type="number"
            value={upload}
            onChange={(e) => setUpload(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="latency">Latencia (ms):</label>
          <input
            id="latency"
            type="number"
            value={latency}
            onChange={(e) => setLatency(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="jitter">Jitter (ms):</label>
          <input
            id="jitter"
            type="number"
            value={jitter}
            onChange={(e) => setJitter(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="lost">Paquetes Perdidos (%):</label>
          <input
            id="lost"
            type="number"
            value={lost}
            onChange={(e) => setLost(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="delayed">Paquetes Retrasados (%):</label>
          <input
            id="delayed"
            type="number"
            value={delayed}
            onChange={(e) => setDelayed(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="bufferbloat">Bufferbloat Test:</label>
          <input
            id="bufferbloat"
            type="text"
            value={bufferbloat}
            onChange={(e) => setBufferbloat(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="webrtc">WebRTC Test:</label>
          <input
            id="webrtc"
            type="text"
            value={webrtc}
            onChange={(e) => setWebrtc(e.target.value)}
          />
        </div>
      </div>
      <div id="docsTab" className="hidden dataTab p-1">
        <h4 className="text-lg md:text-2xl text-main-dark text-center font-bold">
          Documentos del IT Check
        </h4>
        {documents.map((doc, index) => (
          <div key={index} className="flex items-center mt-3">
            <input
              type="file"
              accept=".pdf, .png, .jpg, .jpeg"
              onChange={(e) => handleFileChange(index, e.target.files[0])}
              className="mr-2"
            />
            <button
              onClick={() => handleRemoveDocument(index)}
              className="btn-danger"
            >
              Eliminar
            </button>
          </div>
        ))}
        <div className="w-full justify-end flex mt-3">
          <button onClick={handleAddDocument} className="btn-info">
            Agregar Documento
          </button>
        </div>
      </div>
    </div>
  );
}
