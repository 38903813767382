import React, { useState, useEffect } from "react";
import CheckMenu from "./checkMenu";
import CheckData from "./checkData";

export default function Check({ idUser, idAgent }) {
  document.getElementById("subModule").textContent = "Check";
  document.getElementById("breadcrumbLink").style.display = "block";

  const switchDataTab = (tab) => {
    // Select all elements with the class name 'dataTab'
    const dataTabs = document.querySelectorAll(".dataTab");

    // Hide all dataTabs
    dataTabs.forEach((tab) => {
      tab.classList.add("hidden");
    });

    switch (tab) {
      case "info":
        return document.getElementById("infoTab").classList.remove("hidden");
      case "specs":
        return document.getElementById("specsTab").classList.remove("hidden");
      case "test":
        return document.getElementById("testTab").classList.remove("hidden");
      default:
        return document.getElementById("docsTab").classList.remove("hidden");
    }
  };

  return (
    <>
      <div className="flex flex-col items-center space-y-5 h-full w-full pb-2">
        <h4 className="mt-2 font-bold text-lg md:text-2xl text-main-dark">Individual IT Check for {idAgent}</h4>
        <div className="h-full flex flex-row w-full justify-between">
          <CheckData className="flex" idAgent={535} />
          <CheckMenu switchDataTab={switchDataTab} />
        </div>
        <button className="btn-success">Guardar</button>
      </div>
    </>
  );
}
